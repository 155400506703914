// import googleloading from './assets/google/loading.svg';
// import googlesafe from './assets/google/safe.svg';
// import googlelightning from './assets/google/lightning.svg';
// import GooglePlaybestapp from './assets/google/GooglePlaybestapp.png';
// import GooglePlaybestappright from './assets/google/GooglePlaybestapp-right.png';
// import ipltpng from './assets/image/iplt.png';
// import icontag from './assets/google/icon-tag.png';
// import ic_dun from './assets/google/ic_dun.png';
// import ic_x from './assets/google/ic_x.png';
// import ic_sd from "./assets/google/ic_sd.png";
// import iconshare from "./assets/google/icon-share.png";
// import iconaddwishlist from "./assets/google/icon-addwishlist.png";
// import screen1 from "./assets/image/5660e832-dbd2-ae90-e5cf-ab8415c3538e.webp";
// import screen2 from "./assets/image/259d9b6d-56d5-69b5-3525-1f86e1306b4e.webp";
// import screen3 from "./assets/image/f0170d9b-6a0b-cab8-7778-87a77f62167a.webp";
// import screen4 from "./assets/image/b605e8c6-b801-5b32-b7cb-b684ea1e515f.webp";
// import iconrightarrow from "./assets/google/icon-right-arrow.png";

import './App.css';
import './index.css';
import React  from 'react';

function App() {
    return (
        // <section>
        //     {/* Loading Modal */}
        //     <div className="template-bd">
        //         <div id="loading-box" style={{display:'none'}}>
        //             <div className="mask"></div>
        //             <div className="bd">
        //                 <img width="45" className="rotate" src={googleloading} alt="loading"/>
        //                 <p>Loading...</p>
        //             </div>
        //         </div>
        //         <div id="pop-show">
        //             <div className="mask"></div>
        //             <div className="bd">
        //                 <strong> <img src={googlelightning} alt="loading"/>Install</strong>
        //                 <div className="loading-ci" style={{display: 'none'}}>
        //                     <div className="box"></div>
        //                     <span id="count1">0</span>%
        //                 </div>
        //                 <div className="show-btn">
        //                     <div className="active-box">
        //                         <span><img src={googlesafe} alt="safe"/>Actived</span>
        //                     </div>
        //                     <button className="btn big click_download" name='click_download' id={'click-btn'}>Install Now</button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     {/* top-placeholder */}
        //     <div className='top-placeholder'></div>
        //     <div className="topbar" id="topbar">
        //         <img alt="" className="topbar__left" src={GooglePlaybestapp}/>
        //         <img alt="" className="topbar__right" src={GooglePlaybestappright}/>
        //     </div>
        //     {/* container */}
        //     <div className="container" data-type="INSTALL" id="container">
        //     <div className="header click_download" name='click_download'>
        //         <div className="header__icon">
        //             <img alt="" className="header__icon--img" data-value="app_icon" src={ipltpng}/>
        //             <div className="header__icon--loading" id="iconLoading">
        //                 <svg viewBox="25 25 50 50">
        //                     <circle cx="50" cy="50" fill="none" r="20"></circle>
        //                 </svg>
        //             </div>
        //         </div>
        //     <div className="header__info">
        //         <h1 className="header__info-title"><span data-value="app_name">IPLT</span>
        //             <div className="header__info-title-tag-wrap">
        //                 <img alt="" className="header__info-title-tag" src={icontag}/>
        //             </div>
        //         </h1>
        //         <p className="header__info-progress" id="installProgress">0%</p>
        //         <p className="header__info-play">
        //             <img alt="" className="ic_dun" src={ic_dun}/>
        //             Verified by Play Protect
        //         </p>
        //     </div>
        //     </div>
        //     <div className="info">
        //         <div className="info__view">
        //             <div className="info__view-value"><span data-value="app_score">4.9</span>
        //                 <img alt="" className={ic_x}/>
        //             </div>
        //             <div className="info__view-label" data-value="app_comments">999.9k reviews</div>
        //         </div>
        //         <div className="info__view">
        //             <div className="info__view-value" data-value="app_download">999.9K +</div>
        //             <div className="info__view-label" data-t="downloads.label">Downloads</div>
        //         </div>
        //         <div className="info__view">
        //             <div className="info__view-sui"><span data-value="app_age_limit">18</span>+</div>
        //             <div className="info__view-label"><span data-t="rated_for">Rated for</span>
        //                 <span data-value="app_age_limit">18</span>+
        //             </div>
        //         </div>
        //     </div>
        //     {/* Install button */}
        //     <div className="install-btn shiny-btn click_download" name='click_download' id="install-btn">
        //         <div className="install-btn__ing">
        //             <div className="install-btn__ing__rapid">
        //                 <img alt="" className="ic_x" src={ic_sd}/>
        //                 <span data-t="rapid_install">Rapid Install</span></div>
                    
        //         </div>
        //         <div className="install-btn__play install-btn__view" data-t="play">Play</div>
        //         <div className="install-btn__install install-btn__view" data-t="install">Install</div>
        //     </div>
        //     {/* Share row */}
        //     <div className="share-row">
        //         <div className="share-row__item">
        //             <img alt="" src={iconshare}/>
        //             <div data-t="share">Share</div>
        //         </div>
        //         <div className="share-row__item">
        //             <img alt="" src={iconaddwishlist}/>
        //             <div data-t="add_to_wishlist">Add to wishlist</div>
        //         </div>
        //     </div>
        //     {/* image scroll */}
        //     <div className="img-scroll">
        //         <div className="img-scroll__list" data-value="pic_list">
        //         <div className="img-scroll__view">
        //         <img alt="" src={screen1}/>
        //         </div>
        //         <div className="img-scroll__view">
        //         <img alt="" src={screen2}/>
        //         </div>
        //         <div className="img-scroll__view">
        //         <img alt="" src={screen3}/>
        //         </div>
        //         <div className="img-scroll__view">
        //         <img alt="" src={screen4}/>
        //         </div>
        //         </div>
        //     </div>
        //     <div className="description">
        //         <div className="description__title" data-t="about_this_app">About this app</div>
        //         <img alt="" className="description__right-arrow" src={iconrightarrow}/>
        //         <div className="description__content" style={{whiteSpace: "pre-wrap"}} data-value="app_desc">
        //             IPLT is a MORE BONUS multiplayer online game website, Sign up can get INR 101 BONUS,  If the cumulative monthly revenue reaches INR 2000000, you can obtain an Apple iPhone 14. If the cumulative monthly revenue reaches INR 3000000, you can obtain an Apple MacBook.
        //         </div>
        //     </div>
        //     <div className="description">
        //         <div className="description__title" style={{marginBottom: "5px", fontSize: "14px"}} data-t="updated_on">Updated on</div>
        //         <div className="description__content" id="update-time">2024-03-11</div>
        //         <div className="description__label" id="description-label">
        //             <div className="description__label-item">Slots</div>
        //             <div className="description__label-item">Casino</div>
        //             <div className="description__label-item">Teen Patti</div>
        //             <div className="description__label-item">IPL</div>
        //         </div>
        //     </div>



        // </div>






        // </section>
        <div></div>
    );
}

export default App;
